@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .category-pill {
    @apply inline-flex items-center px-3 py-2 text-xs mr-2 text-center border rounded-full focus:outline-none text-primary bg-white
  }

  .category-pill-selected {
    @apply bg-secondary border-secondary
  }

  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
}